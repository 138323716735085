<template>
  <div :class="{ landingPrices: page && page.landingPrices }">
    <template v-if="page">
      <FeaturedImage
        v-if="page.mainImage"
        :title="page.title"
        :description="page.intro"
        :image="page.mainImage"
        :sponsor="page.sponsor"
      />
      <Blocks
        :blocks="page.body"
        type="page"
        :onepage="page.onepage"
        element="body"
        :overwrite="page.overwrite"
        :sponsor="page.sponsor"
      />
      <div
        class="container page padding-60"
        :class="{ onepage: page.onepage }"
        v-if="page.sources"
      >
        <div class="wrapper">
          <div class="grid">
            <div class="col-12">
              <Sources :sources="page.sources" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sanity from "../../sanity";
import text from "../../helpers/text";
import slug from "../../helpers/slug";
import Blocks from "../../components//Blocks";
import date from "../../helpers/date";
import FeaturedImage from "../../components/FeaturedImage";
import Sources from "../../components/Sources";

export default {
  components: {
    Blocks,
    FeaturedImage,
    Sources,
  },

  data() {
    return {
      loading: false,
      loaded: false,
      page: null,
    };
  },

  metaInfo() {
    return {
      title: this.loading ? "" : `${this.page.title} : `,
    };
  },

  created() {
    this.fetchPage(this.$route.params.slug);
  },

  mounted() {},

  watch: {
    "$route.params.slug": function(slug) {
      this.fetchPage(slug);
    },
  },

  methods: {
    fetchPage(slug) {
      this.loading = true;
      this.page = null;
      sanity
        .fetch(
          `
                    *[_type == "page" && !(_id in path('drafts.**')) && (slug.current == $slug || _id == $slug) && (publishedAt == null || publishedAt < $date)][0]{
                        ...,
                        intro[] {
                          ...,
                          _type == 'button' => {
                              ...,
                              "title": @->title,
                              "text": @->text,
                              "href": @->href,
                              "blank": @->blank,
                              "small": @->small,
                          },
                        },
                        body[] {
                            ...,
                            _type == "team" => {
                                ...@-> {
                                    ...,
                                    image {
                                        ...,
                                        asset {
                                            ...,
                                            "metadata": @->metadata,
                                        },
                                    },
                                },
                            },
                            _type == "category" => {
                                ...,
                                "id": @->id,
                                "slug": @->slug,
                                "title": @->title,
                                "description": @->description,
                                image {
                                    ...,
                                    asset {
                                        ...,
                                        "metadata": @->metadata,
                                    },
                                },
                            },
                            _type == "alignedImage" => {
                                ...,
                                "title": @->title,
                                "visible": @->visible,
                                "text": @->text,
                                "adjustment": @->adjustment,
                                "background": @->background,
                                "alignment": @->alignment,
                                "alignmentText": @->alignmentText,
                                "alignmentWrapper": @->alignmentWrapper,
                                "span": @->span,
                                "file": @->file,
                                "link": @->link,
                                "wrapper": @->wrapper,
                                "icon": @->icon,
                                ...@-> {
                                    mainImage {
                                        ...,
                                        asset {
                                            ...,
                                            "metadata": @->metadata,
                                        },
                                    },
                                },
                            },
                            _type == "pdf" => {
                                ...,
                                "title": @->title,
                                ...@-> {
                                    image {
                                        ...,
                                        asset {
                                            ...,
                                            "metadata": @->metadata,
                                        },
                                    },
                                },
                                "fullFile": @->fullFile,
                            },

                            _type == "image" => {
                                ...,
                                asset {
                                    ...,
                                    "metadata": @->metadata,
                                },
                            },

                            _type == 'button' => {
                                ...,
                                "title": @->title,
                                "text": @->text,
                                "href": @->href,
                                "blank": @->blank,
                                "small": @->small,
                                "body": @->body,
                            },

                            _type == 'price' => {
                                ...,
                                "title": @->title,
                                "description": @->description,
                                "columns": @->columns,
                                "text": @->text,
                                "link": @->link,
                                "price": @->price,
                                "months": @->months,
                                "monthly": @->monthly,
                            },
                        },
                        overwrite[] {
                            ...,
                        },
                        sponsor {
                            ...,
                            "title": @->title,
                            "text": @->text,
                            "logo": @->logo,
                            "info_content": @->info_content,
                        },
                        mainImage {
                            ...,
                            asset {
                                ...,
                                "metadata": @->metadata,
                            }
                        },
                    }
                `,
          {
            slug: slug,
            date: date.currentFullUTCDate(),
          }
        )
        .then(
          (response) => {
            this.page = response;
          },
          (error) => {
            console.warn(error);
          }
        )
        .then(() => {
          this.loading = false;
          this.loaded = true;
        });
    },

    getText(txt) {
      return text(txt);
    },

    getSlug(element) {
      return slug(element);
    },
  },
};
</script>
