<template>
    <div v-if="sources" class="sources" :class="{ 'collapsed': !showSources }">
        <div class="top" @click="clickSources()">
            <h2>Referanser</h2>
        </div>
        <div class="bottom">
            <Blocks :blocks="sources" type="post" element="sources" />
        </div>
    </div>
</template>

<script>
    import Blocks from './Blocks';

    export default {
        components: {
            Blocks,
        },

        props: {
            sources: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                showSources: false,
            };
        },
        
        methods: {
            clickSources() {
                this.showSources = !this.showSources
            },
        },

        mounted() {
        },
    }
</script>