<template>
  <div class="container full featured">
    <div class="wrapper" :class="{ 'no-image': !image }">
      <div class="text">
        <div class="sponsor-content" v-if="sponsor && sponsor.info_content">
          {{ sponsor.info_content }}
        </div>
        <h1>{{ title }}</h1>
        <p v-if="description && typeof description === 'string'">
          {{ description }}
        </p>
        <Blocks
          v-else-if="description"
          :blocks="description"
          type="post"
          element="intro"
        />
        <div
          class="flex flex-group flex-wrap"
          v-if="categories && categories.length > 0"
        >
          <router-link
            v-for="cat in categories"
            :to="{ name: 'category', params: { slug: getSlug(cat) } }"
            :key="cat._key"
            href=""
            class="pill"
            >{{ cat.title }}</router-link
          >
        </div>
      </div>
      <div class="image" v-if="image">
        <img :src="getImageUrl()" />
      </div>
    </div>
  </div>
</template>
<script>
import sanity from "../sanity";
import imageUrlBuilder from "@sanity/image-url";
import slug from "../helpers/slug";

import Blocks from "../components/Blocks";

const imageBuilder = imageUrlBuilder(sanity);
export default {
  components: {
    Blocks,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: [String, Array],
      required: false,
      default: "",
    },
    categories: {
      type: Array,
      required: false,
    },
    sponsor: {
      type: Object,
      required: false,
    },
  },

  methods: {
    getImageUrl() {
      if (!this.image) {
        return "";
      }

      const height = this.image.asset?.metadata?.dimensions?.height;
      const width = this.image.asset?.metadata?.dimensions?.width;

      if (height >= 1080 && width >= 1920) {
        return imageBuilder.image(this.image).height(1080);
      }

      return imageBuilder.image(this.image);
    },

    getTitle() {
      if (!this.title) {
        return "";
      }

      return this.title;
    },

    getPosition() {
      if (!this.image) {
        return "";
      }

      return this.image.position;
    },

    getAspectRatioClass() {
      const validAspectRatios = ["4:3", "16:9", "1:1"];
      const aspectRatio = this.image.forcedAspectRatio ?? "";

      if (!validAspectRatios.includes(aspectRatio)) {
        return null;
      }

      return `aspectRatio${aspectRatio.replace(":", "by")}`;
    },

    getSlug(element) {
      return slug(element);
    },
  },
};
</script>
